<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">{{ year }} &copy; Near Me</div>
      <!-- <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Design & Develop by Themesbrand
                </div>
            </div> -->
    </div>
  </div>
</footer>
