import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { RouterModule } from "@angular/router";
import { FiltersComponent } from "./filters/filters.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FieldErrorModule } from "./field-error/field-error.module";
import { CutStringsPipe } from "src/app/core/pipes/cut-strings.pipe";
import { NoImgComponent } from "./no-img/no-img.component";
@NgModule({
  declarations: [
    PagetitleComponent,
    FiltersComponent,
    CutStringsPipe,
    NoImgComponent,
  ],
  exports: [
    PagetitleComponent,
    FiltersComponent,
    CutStringsPipe,
    NoImgComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
    ReactiveFormsModule,
    FieldErrorModule,
  ],
})
export class UIModule {}
