import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    icon: "bx-home-circle",
    link: "/home",
    role: "admin",
    permission: "view_dashboard_main_page",
  },
  {
    id: 2,
    label: "Users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_clients",
    subItems: [
      {
        id: 21,
        label: "Users",
        link: "/users",
        parentId: 2,
        role: "admin",
        permission: "view_users",
      },
      {
        id: 22,
        label: "Drivers",
        link: "/drivers",
        parentId: 2,
        role: "admin",
        permission: "view_users",
      },
      {
        id: 23,
        label: "Join Requests",
        link: "/joinRequests",
        parentId: 2,
        role: "admin",
        permission: "view_join_requests",
      },
    ],
  },
  {
    id: 9,
    label: "Trips",
    icon: "bx bxs-plane-alt",
    role: "admin",
    permission: "view_trips",
    subItems: [
      {
        id: 91,
        label: "Trips",
        link: "/trips",
        parentId: 9,
        role: "admin",
        permission: "view_trip_requests",
      },
      {
        id: 92,
        label: "Tracking Active Drivers",
        link: "/activeTrips",
        parentId: 9,
        role: "admin",
        permission: "view_active_trips",
      },
      {
        id: 93,
        label: "Trips Cancelation Reasons",
        link: "/cancelationReasons",
        parentId: 9,
        role: "admin",
        permission: "view_cancelation_reasons",
      },
    ],
  },
  {
    id: 4,
    label: "Finance",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_banks",
    subItems: [
      {
        id: 41,
        label: "Banks",
        link: "/banks",
        parentId: 4,
        role: "admin",
        permission: "view_banks",
      },
      {
        id: 42,
        label: "Bank Accounts",
        link: "/bankAccounts",
        parentId: 4,
        role: "admin",
        permission: "view_bank_accounts",
      },
      {
        id: 43,
        label: "Bank Transfers",
        link: "/bankTransfers",
        parentId: 4,
        role: "admin",
        permission: "view_bank_transfers",
      },
      {
        id: 44,
        label: "Payments",
        link: "/payments",
        icon: "bx bxl-visa",
        role: "admin",
        permission: "view_payment_methods",
      },
    ],
  },
  {
    id: 5,
    label: "Vehicles",
    icon: "bx bx-car",
    role: "admin",
    permission: "view_vehicles",
    subItems: [
      {
        id: 51,
        label: "Vehicles",
        link: "/vehicles",
        parentId: 5,
        role: "admin",
        permission: "view_vehicles",
      },
    ],
  },
  {
    id: 6,
    label: "Coupons & Codes",
    icon: "bx bxs-discount",
    role: "admin",
    permission: "view_coupons",
    subItems: [
      {
        id: 61,
        label: "Coupons & Vouchers",
        link: "/vouchers",
        parentId: 6,
        role: "admin",
        permission: "view_coupons",
      },
      {
        id: 62,
        label: "Charging Codes",
        link: "/chargingCodes",
        parentId: 6,
        role: "admin",
        permission: "view_codes",
      },
    ],
  },
  {
    id: 8,
    label: "Reports",
    icon: "bx bxs-report",
    role: "admin",
    permission: "view_reports",
    subItems: [
      {
        id: 81,
        label: "Complaints Reports",
        link: "/reports/issues",
        parentId: 8,
        role: "admin",
        permission: "view_issues-reports",
      },
      {
        id: 82,
        label: "Lost Items Reports",
        link: "/reports/lost-items",
        parentId: 8,
        role: "admin",
        permission: "view_reports",
      },
      {
        id: 83,
        label: "Refunds Reports",
        link: "/reports/refunds",
        parentId: 8,
        role: "admin",
        permission: "view_reports",
      },
    ],
  },
  {
    id: 3,
    label: "Pages",
    icon: "bx bx-book-open",
    role: "admin",
    permission: "view_pages",
    subItems: [
      {
        id: 31,
        label: "Pages",
        link: "/pages",
        parentId: 3,
        role: "admin",
        permission: "view_pages",
      },
    ],
  },
  {
    id: 7,
    label: "Support",
    icon: "bx bx-support",
    role: "admin",
    permission: "view_coupons",
    subItems: [
      {
        id: 71,
        label: "Complaints",
        link: "/issues",
        parentId: 7,
        role: "admin",
        permission: "view_issues",
      },
      {
        id: 72,
        label: "Lost Items",
        link: "/lost-items",
        parentId: 7,
        role: "admin",
        permission: "view_issues",
      },
    ],
  },
  {
    id: 12,
    label: "Regions",
    icon: "bx bx-world",
    role: "admin",
    permission: "view_regions",
    subItems: [
      {
        id: 121,
        label: "Cities",
        link: "/regions/cities",
        parentId: 12,
        role: "admin",
        permission: "view_cities",
      },
    ],
  },
  {
    id: 9,
    label: "Notification",
    icon: "bx bx-bell",
    role: "admin",
    permission: "view_notifications",
    subItems: [
      {
        id: 92,
        label: "Send Notification",
        link: "/notifications/send",
        parentId: 9,
        role: "admin",
        permission: "create_notifications",
      },
    ],
  },
  {
    id: 10,
    label: "Wallet",
    icon: "bx bx-wallet",
    role: "admin",
    permission: "view_trips",
    subItems: [
      {
        id: 101,
        label: "Wallet Charging Categories",
        link: "/creidets",
        parentId: 10,
        role: "admin",
        permission: "view_creidets",
      },
    ],
  },
  {
    id: 11,
    label: "Settings",
    icon: "bx bx-cog",
    role: "admin",
    permission: "view_settings",
    subItems: [
      {
        id: 111,
        label: "Trip Settings",
        link: "/settings/trip",
        parentId: 11,
        role: "admin",
        permission: "view_settings",
      },
      {
        id: 112,
        label: "App Settings",
        link: "/settings/app",
        parentId: 11,
        role: "admin",
        permission: "view_settings",
      },
    ],
  },
];
