import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { User } from "../../models/auth.models";
import { HttpClientService } from "../http/http.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(private http: HttpClientService, private router: Router) {
    this.expiredTokenSubject = new Subject();
  }

  public set User(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public get User(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public get getAdmin() {
    if (this.User && this.User.type === "admin") {
      return "admin";
    }
  }

  public HasPermission(permission) {
    if (this.User.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }
  public isAdmin() {
    if (this.User && this.User.type === "admin") {
      return true;
    }

    return false;
  }

  public logout() {
    this.http.post({}, "auth/logout").subscribe(
      (_) => this.removeUser(),
      (_) => this.removeUser()
    );
  }

  removeUser() {
    localStorage.removeItem("user");
    this.router.navigate(["account/login"]);
  }

  public Login(body: { email: string; password: string; type: "admin" }) {
    return this.http.post(body, "auth/login");
  }

  public companyRegister(body) {
    return this.http.postFormData(body, "auth/register");
  }
}
