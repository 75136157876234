import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { FormManage } from "src/app/core/helpers/formVaild";
import {
  IFilterData,
  SavedFilterData,
} from "src/app/core/models/filters.model";
import { CachingService } from "src/app/core/services/caching/caching.service";
import {
  FILTERS,
  FiltersService,
} from "src/app/core/services/filters/filters.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent extends FormManage implements OnInit, OnDestroy {
  filterForm: FormGroup;
  addFilterForm: NgForm;
  modalReference;
  @Input() filterData: IFilterData;
  @Input() OPTIONS: string[];
  @Input() FilterType: FILTERS;
  formSubscription: Subscription;
  showActions = false;
  savedFilters: SavedFilterData[] = [];

  tripStatus = [
    { name: "Pending", color: "#C1AC09", value: 1 },
    { name: "Canceled", color: "#FF0000", value: 2 },
    { name: "Waiting driver arrival", color: "#2ED573", value: 3 },
    { name: "Captain arrived", color: "#0058FF", value: 4 },
    { name: "Trip started", color: "#BEBBBB", value: 5 },
    { name: "Finshed", color: "#BEBBBB", value: 6 },
    { name: "No captain available", color: "#C1AC09", value: 7 },
    { name: "Paid Trip", color: "#BEBBBB", value: 8 },
  ];

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private cachingService: CachingService,
    private filtersService: FiltersService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAllSavedFilters();
    this.initForm();
    if (this.filterData) {
      this.setDataToForm(this.filterData);
    }
  }

  initForm() {
    this.filterForm = new FormGroup({});
    this.setForm(this.filterForm);

    // loop through the filter options and set form controllers
    if (this.OPTIONS.length > 0) {
      this.OPTIONS.forEach((key) => {
        this.addControllerTOTheForm(key, null);
      });
    }

    this.filterActionsHandler();
  }

  // get filters from local storage if not exist we get it from server
  getAllSavedFilters() {
    if (this.cachingService.isKeyExist(this.filtersService.cacheKeyName)) {
      const allAppFilters = JSON.parse(
        this.filtersService.getAllFiltersFromStorage()
      );
      this.savedFilters =
        this.FilterType in allAppFilters &&
        JSON.parse(allAppFilters[this.FilterType])
          ? JSON.parse(allAppFilters[this.FilterType])
          : [];
    } else {
      this.getUpdatedFilters();
    }
  }

  // get filters from server and update the local storage
  getUpdatedFilters() {
    this.filtersService.getFiltersFromService().subscribe({
      next: (res) => {
        res.data
          ? this.cachingService.set(
              this.filtersService.cacheKeyName,
              JSON.stringify(res.data)
            )
          : "";
        const Filters =
          res.data && JSON.parse(res.data[this.FilterType])
            ? JSON.parse(res.data[this.FilterType])
            : [];
        this.savedFilters = Filters;
      },
      error: (err) => {
        Swal.fire(this.translate.instant("error"), err, "error");
      },
    });
  }

  // function control show/hide of filter actions
  filterActionsHandler() {
    this.formSubscription = this.filterForm.valueChanges.subscribe((res) => {
      const formRes = { ...res };
      Object.keys(formRes).forEach((el) => {
        if (formRes[el] === null || formRes[el] === "") delete formRes[el];
      });

      if (Object.keys(formRes).length === 0) {
        this.showActions = false;
      } else {
        this.showActions = true;
      }
    });
  }

  applySavedFilter(filter: SavedFilterData) {
    const keysMustParsed = ["return_all", "page", "active", "status"];

    // parse keys that have number values before set the form
    Object.keys(filter).forEach((key) => {
      if (keysMustParsed.includes(key)) {
        filter[key] = JSON.parse(filter[key]);
      } else {
        filter[key] = filter[key];
      }
    });

    this.setDataToForm(filter);
    this.Submit();
  }

  /**
   * Open modal
   * @param content modal content
   */
  MaryModal(content: any) {
    this.modalReference = this.modalService.open(content);
  }

  addFilter(form: NgForm) {
    // get filter from form
    const newFilter = {
      ...form.value,
      ...this.filterForm.value,
    };

    // remove null properties
    Object.keys(newFilter).forEach((key) => {
      if (newFilter[key] === null) {
        delete newFilter[key];
      }
    });

    // add to filters array
    this.savedFilters.push(newFilter);
    this.updateFiltersOnServer();
    this.modalReference.close();
  }

  onRemoveFilter(event, index) {
    event.stopPropagation();
    Swal.fire({
      title:
        this.translate.instant("Are you sure you want to delete") +
        " " +
        `${this.savedFilters[index].name}` +
        this.translate.instant("?"),
      text: this.translate.instant("You won't be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("Cancel"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: this.translate.instant("Yes, delete it!"),
    }).then((result) => {
      if (result.isConfirmed) {
        this.savedFilters.splice(index, 1);
        this.updateFiltersOnServer();
      }
    });
  }

  updateFiltersOnServer() {
    // build object that will send to server
    const newFilterData = {
      type: this.FilterType,
      filter: this.savedFilters,
    };

    // send the filter and update all filters in the app
    this.filtersService.addFilter(newFilterData).subscribe({
      next: () => {
        this.getUpdatedFilters();
      },
      error: (err) => {
        Swal.fire(this.translate.instant("error"), err, "error");
      },
    });
  }

  Submit() {
    const filterOptions = { ...this.filterForm.value };

    // REMOVE the empty properties from the object before navigate
    Object.keys(filterOptions).forEach((key) => {
      if (filterOptions[key] == "null" || filterOptions[key] == null) {
        delete filterOptions[key];
      }
    });
    this.router.navigate([], { queryParams: filterOptions });
  }

  reset() {
    this.filterForm.reset();
    this.router.navigate([], { queryParams: {} });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }
}
