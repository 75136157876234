<ng-template #RoderickDataModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ "Filter Name" | translate }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body">
    <form #addFilterForm="ngForm">
      <div class="mb-3">
        <input
          id="name"
          name="name"
          type="text"
          class="form-control"
          ngModel
          required
          [placeholder]="'Filter Name' | translate"
        />

        <app-field-error
          [displayError]="
            addFilterForm.invalid &&
            addFilterForm.touched &&
            !addFilterForm.pristine
          "
          [errorMsg]="'Please Enter Filter Number' | translate"
        ></app-field-error>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="addFilter(addFilterForm)"
      [disabled]="addFilterForm.invalid"
    >
      {{ "Save" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-bs-dismiss="modal"
      (click)="modal.close('Close click')"
    >
      {{ "Cancel" | translate }}
    </button>
  </div>
</ng-template>

<h3 class="mb-3 font-size-14">
  {{
    "You Can Filter Using Following Options And Also Save Your Filters For Saving Time"
      | translate
  }}
</h3>

<form [formGroup]="filterForm" (ngSubmit)="Submit()">
  <div class="row">
    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.user_name">
      <input
        id="user_name"
        name="user_name"
        type="text"
        class="form-control"
        formControlName="user_name"
        [placeholder]="'User Name' | translate"
      />
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.driver_name">
      <input
        id="driver_name"
        name="driver_name"
        type="text"
        class="form-control"
        formControlName="driver_name"
        [placeholder]="'Driver Name' | translate"
      />
    </div>
    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.address">
      <input
        id="address"
        name="address"
        type="text"
        class="form-control"
        formControlName="address"
        [placeholder]="'Address' | translate"
      />
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.keyword">
      <input
        id="keyword"
        name="keyword"
        type="text"
        class="form-control"
        formControlName="keyword"
        [placeholder]="'Search' | translate"
      />
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.email">
      <input
        id="email"
        name="email"
        type="text"
        class="form-control"
        formControlName="email"
        [placeholder]="'Email' | translate"
      />
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.phone">
      <input
        id="Phone"
        name="Phone"
        type="text"
        class="form-control"
        [placeholder]="'Enter Your Phone...' | translate"
        formControlName="phone"
      />
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.active">
      <ng-select
        formControlName="active"
        [placeholder]="'Status' | translate"
        [clearable]="false"
      >
        <ng-option [value]="1">{{ "Active" | translate }}</ng-option>
        <ng-option [value]="0">{{ "InActive" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.wasl_status">
      <ng-select
        formControlName="wasl_status"
        [placeholder]="'Driver Inspection Status' | translate"
        [clearable]="false"
      >
        <ng-option [value]="'INVALID'">{{
          "Unauthorized Drivers" | translate
        }}</ng-option>
        <ng-option [value]="'VALID'">{{
          "Authorized Drivers" | translate
        }}</ng-option>
      </ng-select>
    </div>

    <div
      class="col-md-2 mb-3"
      *ngIf="this.filterForm.controls.driver_license_expiration"
    >
      <ng-select
        formControlName="driver_license_expiration"
        [placeholder]="'Driver license expiration date' | translate"
        [clearable]="false"
      >
        <ng-option [value]="1">{{ "Expired License" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Valid license" | translate }}</ng-option>
      </ng-select>
    </div>

    <div
      class="col-md-2 mb-3"
      *ngIf="this.filterForm.controls.vehicle_license_expiration"
    >
      <ng-select
        formControlName="vehicle_license_expiration"
        [placeholder]="'Vehicle license expiration date' | translate"
        [clearable]="false"
      >
        <ng-option [value]="1">{{ "Expired License" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Valid license" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.is_used">
      <ng-select
        formControlName="is_used"
        [placeholder]="'Usage' | translate"
        [clearable]="false"
      >
        <ng-option [value]="1">{{ "Already Used" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Not Used Yet" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.status">
      <ng-select
        formControlName="status"
        [placeholder]="'Status' | translate"
        [clearable]="false"
      >
        <ng-option
          *ngFor="let status of tripStatus"
          [ngStyle]="{ color: status?.color }"
          [value]="status?.value"
          >{{ status?.name | translate }}</ng-option
        >
      </ng-select>
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.payment_method">
      <ng-select
        formControlName="payment_method"
        [placeholder]="'Payment Method' | translate"
        [clearable]="false"
      >
        <ng-option [value]="'cash'">{{ "Cash" | translate }}</ng-option>
        <ng-option [value]="'visa'">{{ "Visa" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-3" *ngIf="this.filterForm.controls.type">
      <ng-select
        formControlName="type"
        [placeholder]="'Type' | translate"
        [clearable]="false"
      >
        <ng-option [value]="'deposit'">{{ "deposit" | translate }}</ng-option>
        <ng-option [value]="'withdraw'">{{ "withdraw" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-5 mb-3">
      <button
        type="submit"
        *ngIf="showActions"
        class="btn btn-primary w-sm ms-2 mb-3"
      >
        {{ "Search" | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger w-sm ms-2 mb-3"
        *ngIf="showActions"
        (click)="reset()"
      >
        {{ "Reset" | translate }}
      </button>

      <button
        type="button"
        class="btn btn-warning w-sm ms-2 mb-md-3 btn-label waves-light"
        *ngIf="showActions"
        (click)="MaryModal(RoderickDataModal)"
      >
        {{ "Save Filter" | translate }}
        <i class="mdi mdi-plus label-icon"></i>
      </button>

      <div class="btn-group ms-2" role="group" ngbDropdown>
        <button
          id="btnGroupVerticalDrop1"
          ngbDropdownToggle
          type="button"
          class="btn dropdown-toggle w-sm saved-btn"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ "Saved Filters" | translate }} <i class="mdi mdi-chevron-down"></i>
        </button>
        <div
          class="dropdown-menu"
          ngbDropdownMenu
          aria-labelledby="btnGroupVerticalDrop1"
        >
          <div class="text-center" *ngIf="savedFilters.length == 0">
            {{ "No Filters" | translate }}
          </div>
          <a
            class="dropdown-item filter-item"
            role="button"
            *ngFor="let filter of savedFilters; let i = index"
            (click)="applySavedFilter(filter)"
          >
            <i
              class="fas fa-trash-alt text-danger"
              (click)="onRemoveFilter($event, i)"
              [title]="'Remove Filter' | translate"
            ></i>
            {{ filter.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
