import { Injectable } from "@angular/core";
import { User } from "../../models/auth.models";
import { SavedFilterData } from "../../models/filters.model";
import { CachingService } from "../caching/caching.service";
import { HttpClientService } from "../http/http.service";

// app filters type
export type FILTERS =
  | "users"
  | "trips"
  | "vehicles"
  | "coupons"
  | "charging-codes";

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  cacheKeyName = "dashboard_filters";
  userId;
  userType;

  constructor(
    private cachingService: CachingService,
    private http: HttpClientService
  ) {
    const user = this.cachingService.get("user") as User;
    this.userId = user.id;
    this.userType = user.type;
  }

  getAllFiltersFromStorage(): string {
    if (this.cachingService.isKeyExist(this.cacheKeyName)) {
      return this.cachingService.get(this.cacheKeyName);
    } else {
      return JSON.stringify({});
    }
  }

  getFiltersFromService() {
    return this.http.get("filters");
  }

  addFilter(newFilter) {
    return this.http.postFormData(newFilter, "filters");
  }
}
